export const EntityTypes = {
    Owner:1,
    Applicant: 2,
    Delivery: 3,
    Dealer: 4
}

export const DeliveryTypes = {
    OnRequest : 1,
    Dealer: 2,
    Individual: 3,
    Company: 4
}