import styled from "@emotion/styled"
import mq from "@styles/mq"
import theme from "@styles/theme"
import facepaint from "facepaint"
import React from "react"
import { Grid, Segment } from "semantic-ui-react"

const breakpoints = facepaint([
  "@media(min-width: 850px)",
  "@media(min-width: 1024px)",
  "@media(min-width: 1170px)",
])

export const NavHeaderSegmentStyle = styled(Segment)`
  &&&& {
    background-color: ${theme.mobileTheme.bgColor};
    color: ${theme.mobileTheme.color};
    border-radius: unset;
    border: unset;
    box-shadow: unset !important;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    .ui.container {
      width: fit-content;
    }
    ${breakpoints({
      paddingTop: ["0px", "1rem", "1rem", "1rem"],
      paddingBottom: ["0px", "1rem", "1rem", "1rem"],
    })}
  }
`

export const Back = styled.div`
  float: left;
  cursor: pointer;
  i {
    color: ${theme.brand.colors.green};
    vertical-align: top !important;
  }
  span {
    vertical-align: text-top;
    color: ${theme.brand.colors.green}
  }
  ${mq({
    fontSize: ["13px !important;", "16px !important;", "16px !important;"],
  })}
`

export const Info = styled(({color, ...rest}) => <div {...rest}/>)`
  float: right;
  font-weight: bold;
  color: ${props => props.color};
`