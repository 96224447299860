import styled from "@emotion/styled"
import { Grid, GridColumn } from "semantic-ui-react"
import theme from "@styles/theme"
import { Link } from "gatsby"

export const DetailSectionColumn = styled(Grid.Column)`
    padding-top: 20px !important;
    padding-bottom: 30px !important;
    color: ${theme.mobileTheme.color} !important;

    &:before {
        position: absolute;
        content: "";
        top: 0em;
        left: 0px;
        width: calc(100% - 2rem);
        height: 3px;
        margin: 0% 1rem;
        background-color: ${theme.brand.colors.darkGrey};
    }

    &:last-child{
        margin-bottom: 30px;
        &:after {
            position: absolute;
            content: "";
            bottom: 0em;
            left: 0px;
            width: calc(100% - 2rem);
            height: 3px;
            margin: 0% 1rem;
            background-color: ${theme.brand.colors.darkGrey};
        }
    }
`
export const SummaryItemContainer = styled(Grid.Column)`
    padding-top: 5px !important;
    padding-bottom: 5px !important;
`
export const SummaryItemContainerRow = styled(Grid.Row)`
    padding-top: 0 !important;
    padding-bottom: 0 !important;
`
export const SummaryEditLink = styled(Link)`
    padding: 5px;
    padding-top: 10px;
    color: ${theme.mobileTheme.buttonBgColor};
    &.disabled {
        color: ${theme.brand.colors.darkGrey};
    }
    :hover {
        border-bottom: 3px solid ${theme.mobileTheme.buttonBgColor};
        color: ${theme.mobileTheme.buttonBgColor};
    }

    :focus {
        border: 3px solid ${theme.brand.colors.black};
        color: ${theme.mobileTheme.buttonBgColor};
        border-radius: 3px;
    }
`

export const DetailSectionHeading = styled(GridColumn)`
    margin-bottom: 20px;
`