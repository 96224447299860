import React from "react"
import { Paragraph1, H6, Paragraph2 } from "@styles/Global.styles"
import { Grid, Icon, Input, List, Radio } from "semantic-ui-react"
import { LaybyPaymentListContent, LaybyPaymentSummaryRow, LaybyPaymentSummaryRowBordered } from "./MakePayment.styles";
import { Checkout } from "@models/Checkout/Checkout";
import { LaybyQuote } from "@models/index";
import { LaybyService } from "@services/index";
import { BasketResponseModel } from "@models/Basket/BasketResponseModel";
import moment from "moment";
import { KpFormDatePickerIcon, KpFormDatePickerInput, KpFormDayPickerInput, KpFormDayPickerInputIconContiner } from "@styles/Global.forms";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import theme from "@styles/theme";
import { LBCheckbox } from "@components/MakePayment/MakePayment.styles";
import { Checkbox } from "aws-amplify-react";

interface LaybyPaymentProps {
    apiUrl: string
    updateIsLayby: Function
    updateLaybyPreferredPaymentDay: Function,
    updateLaybyQuote: Function
    updatePaymentType: Function
    updateIsAutopay: Function
    checkout: Checkout
    basket: BasketResponseModel
    laybyInfo: string
}
interface LaybyPaymentState {
    loadingLaybyQuotes: boolean
    laybyQuotes: LaybyQuote[]
    selectedLaybyQuoteIndex: number;
    selectedLaybyFrequencyName: string
    selectedPaymentDay: Date
    firstPaymentDate: Date
}

class LaybyPayment extends React.Component<LaybyPaymentProps, LaybyPaymentState>{
    state: LaybyPaymentState = {
        laybyQuotes: [],
        loadingLaybyQuotes: true,
        selectedLaybyFrequencyName: 'monthly',
        selectedLaybyQuoteIndex: 2,
        selectedPaymentDay: this.props.checkout.laybyPreferredPaymentDay,
        firstPaymentDate: moment().toDate()
    }

    componentDidMount() {
        if (this.state.laybyQuotes.length === 0) {
            this.loadLaybyQuotes();
        }
    }

    updateIsLayby = (isLayby: boolean) => {
        this.props.updateIsLayby(isLayby);

        if (isLayby && this.state.laybyQuotes.length === 0) {
            this.loadLaybyQuotes();
        }
    }

    formatDate = (date: Date, format: string, locale: string) => {
        return moment(date).format('DD/MM/yyyy');
    }

    selectLaybyQuote = (index: number) => {
        if (index !== this.state.selectedLaybyQuoteIndex) {
            var firstPaymentDay = moment().add(this.state.laybyQuotes[index].installmentFrequency, 'day');

            this.setState({
                selectedLaybyQuoteIndex: index,
                firstPaymentDate: firstPaymentDay.toDate()
            });
            const selectedLaybyQuote = this.state.laybyQuotes[index];
            this.props.updateLaybyQuote(selectedLaybyQuote);
            this.props.updateLaybyPreferredPaymentDay(moment().add(selectedLaybyQuote.installmentFrequency, 'day').toDate())
            if (selectedLaybyQuote.bracketName !== 'Monthly') {
                this.props.updatePaymentType(1);
                this.props.updateIsAutopay(false);
            }
            else {
                this.props.updateIsAutopay(false);
            }
        }
    }

    // selectPreferredPaymentDay = (day: string) => {
    //     var firstPaymentDay = moment().add(this.state.laybyQuotes[this.state.selectedLaybyQuoteIndex].installmentFrequency, 'day').day(day);
    //     var originalPaymentDay = moment().add(this.state.laybyQuotes[this.state.selectedLaybyQuoteIndex].installmentFrequency, 'day');

    //     if(firstPaymentDay.isBefore(originalPaymentDay, 'day')){
    //         firstPaymentDay.add(1, 'w').day(day);
    //     }
    //     this.props.updateLaybyPreferredPaymentDay(day);
    //     this.setState({
    //         selectedPaymentDay: day,
    //         firstPaymentDate: firstPaymentDay.toDate()
    //     });
    // }

    calculateLaybyTotal = () => {
        const monthlyLayby = this.state.laybyQuotes.find((laybyQuote) => {
            return laybyQuote.bracketName === 'Monthly';
        })

        if (monthlyLayby !== undefined) {
            return this.calculatePaymentSurcharge(monthlyLayby.paymentAmount) * (monthlyLayby.installmentCount + 1);
        }
        else {
            return 0;
        }
    }

    calculatePaymentSurcharge = (amount: number) => {
        if (this.props.checkout.paymentType === 1) return amount + (amount * 0.019);
        return amount;
    }

    handleDayChange = (day: Date) => {
        this.props.updateLaybyPreferredPaymentDay(day);
    }

    loadLaybyQuotes = () => {
        this.setState({
            loadingLaybyQuotes: true
        });

        LaybyService.getLaybyQuotesForAmount(this.props.apiUrl, this.props.basket.summary.finalTotal).then((laybyQuotes: any[]) => {
            const laybyQuotesMapped = laybyQuotes.map((laybyQuote) => {
                return {
                    bracketName: laybyQuote.BracketName,
                    installmentFrequency: laybyQuote.DaysInPeriod,
                    installmentCount: laybyQuote.NumPeriods,
                    depositAmount: laybyQuote.DepositAmount,
                    paymentAmount: laybyQuote.MinRegularAmount,
                    totalAmount: laybyQuote.LayByAmount
                }
            });

            if (laybyQuotesMapped[this.state.selectedLaybyQuoteIndex]) {
                this.props.updateLaybyQuote(laybyQuotesMapped[this.state.selectedLaybyQuoteIndex]);
                this.props.updateLaybyPreferredPaymentDay(moment().add(laybyQuotesMapped[this.state.selectedLaybyQuoteIndex].installmentFrequency, 'day').toDate());
            }

            this.setState({
                loadingLaybyQuotes: false,
                laybyQuotes: laybyQuotesMapped,
                firstPaymentDate: moment().add('d', laybyQuotesMapped[this.state.selectedLaybyQuoteIndex].installmentFrequency).toDate()
            });
        });
    }


    render() {
        const { laybyQuotes, selectedLaybyQuoteIndex } = this.state;
        const selectedLaybyQuote = laybyQuotes[selectedLaybyQuoteIndex];

        return (
            <div>
                {
                    this.state.loadingLaybyQuotes &&
                    <Grid.Column width={16}>
                        <Paragraph1>
                            Loading...
                            <Icon name='circle notch' size='small' loading />
                        </Paragraph1>
                    </Grid.Column>
                }
                {
                    !this.state.loadingLaybyQuotes && this.state.laybyQuotes.length > 0 &&
                    <Grid>
                        <Grid.Column width={16}>
                            <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.laybyInfo }} />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <H6>Choose your Layby Frequency</H6>
                            <List horizontal>
                                {
                                    this.state.laybyQuotes.map((laybyQuote, index) => {
                                        return (
                                            <List.Item key={index}>
                                                
                                                <LaybyPaymentListContent disabled={index !== this.state.selectedLaybyQuoteIndex} onClick={() => this.selectLaybyQuote(index)}>
                                                    <Radio checked={index === this.state.selectedLaybyQuoteIndex}></Radio>&nbsp;
                                                    {laybyQuote.bracketName}
                                                </LaybyPaymentListContent>
                                            </List.Item>
                                        )
                                    })
                                }
                            </List>
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <H6>Choose your NEXT Payment Date</H6>
                            <DayPickerInput
                                formatDate={this.formatDate}
                                component={(props: any) => {
                                    return (
                                        <KpFormDayPickerInput {...props}>
                                            <KpFormDayPickerInputIconContiner>
                                                <KpFormDatePickerIcon name='calendar outline' size='huge' />
                                            </KpFormDayPickerInputIconContiner>
                                            <span>{moment(this.props.checkout.laybyPreferredPaymentDay).format('DD/MM/yyyy')}</span>
                                        </KpFormDayPickerInput>
                                    )
                                }}
                                onDayChange={this.handleDayChange}
                                value={moment(this.props.checkout.laybyPreferredPaymentDay).format('DD/MM/yyyy')}
                                dayPickerProps={{
                                    onKeyDown: () => { return; },
                                    numberOfMonths: moment().add(selectedLaybyQuote.installmentFrequency, 'days').isSame(moment().add(selectedLaybyQuote.installmentFrequency, 'days').add(1, 'week'), 'month') ? 1 : 2,
                                    month: moment().add(selectedLaybyQuote.installmentFrequency, 'days').toDate(),
                                    fromMonth: moment().add(selectedLaybyQuote.installmentFrequency, 'days').toDate(),
                                    toMonth: moment().add(selectedLaybyQuote.installmentFrequency, 'days').isSame(moment().add(selectedLaybyQuote.installmentFrequency, 'days').add(1, 'week'), 'month') ? moment().add(selectedLaybyQuote.installmentFrequency, 'days').toDate() : moment().add(selectedLaybyQuote.installmentFrequency, 'days').add(1, 'month').toDate(),
                                    disabledDays: [
                                        {
                                            after: moment().add(selectedLaybyQuote.installmentFrequency, 'days').add(1, 'week').toDate(),
                                            before: moment().add(selectedLaybyQuote.installmentFrequency, 'days').toDate()
                                        },
                                        {
                                            daysOfWeek: [0, 6]
                                        }
                                    ]
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <Grid>
                                <LaybyPaymentSummaryRow>
                                    <Grid.Column width='16'>
                                        <H6>Layby Summary</H6>
                                    </Grid.Column>
                                </LaybyPaymentSummaryRow>
                                <LaybyPaymentSummaryRow>
                                    <Grid.Column width='12' textAlign='left'>
                                        <Paragraph2>
                                            Layby Order Total {this.props.checkout.paymentType === 1 ? '(incl. $' + (selectedLaybyQuote.totalAmount * 0.019).toFixed(2) + ' processing fee*)' : ''}
                                        </Paragraph2>
                                    </Grid.Column>
                                    <Grid.Column width='4' textAlign='right'>
                                        <Paragraph2>
                                            ${this.calculatePaymentSurcharge(selectedLaybyQuote.totalAmount).toFixed(2)}
                                        </Paragraph2>
                                    </Grid.Column>
                                </LaybyPaymentSummaryRow>
                                <LaybyPaymentSummaryRowBordered>
                                    <Grid.Column width='12' textAlign='left'>
                                        <Paragraph2>
                                            <strong>First Payment (Deposit) Due Now</strong>
                                        </Paragraph2>
                                    </Grid.Column>
                                    <Grid.Column width='4' textAlign='right'>
                                        <Paragraph2>
                                            <strong>${this.calculatePaymentSurcharge(selectedLaybyQuote.paymentAmount).toFixed(2)}</strong>
                                        </Paragraph2>
                                    </Grid.Column>
                                </LaybyPaymentSummaryRowBordered>
                                <LaybyPaymentSummaryRow></LaybyPaymentSummaryRow>
                                <LaybyPaymentSummaryRow>
                                    <Grid.Column width='16' textAlign='left'>
                                        <Paragraph2 color={theme.mobileTheme.color}>
                                            {selectedLaybyQuote.installmentCount} x ${this.calculatePaymentSurcharge(selectedLaybyQuote.paymentAmount).toFixed(2)} Remaining Payments (Due every {selectedLaybyQuote.installmentFrequency} days)
                                        </Paragraph2>
                                    </Grid.Column>
                                </LaybyPaymentSummaryRow>
                                <LaybyPaymentSummaryRow>
                                    <Grid.Column width='6' textAlign='left'>
                                        <Paragraph2 color={theme.mobileTheme.color}>
                                            Next Payment: {moment(this.props.checkout.laybyPreferredPaymentDay).format('DD MMMM YYYY')}
                                        </Paragraph2>
                                    </Grid.Column>
                                    <Grid.Column width='10' textAlign='left'>
                                        <Paragraph2 color={theme.mobileTheme.color}>
                                            Estimated Completion: {moment(this.props.checkout.laybyPreferredPaymentDay).add(selectedLaybyQuote.installmentFrequency * (selectedLaybyQuote.installmentCount), 'days').format('DD MMMM YYYY')}
                                        </Paragraph2>
                                    </Grid.Column>
                                </LaybyPaymentSummaryRow>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                }
            </div>

        )
    }
}


export default LaybyPayment